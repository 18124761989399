/** @jsx jsx */
import { jsx, Container } from 'theme-ui'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'

export default ({ aboutImage, aboutText }) => (
  <section
    sx={{
      bg: 'muted',
      display: 'grid',
      gridTemplateColumns: ['auto', '2fr 3fr'],
      padding: [4, 5, 6],
      alignItems: 'center',
    }}
  >
    <div sx={{ variant: 'image.outlined' }}>
      <Img title='Madalyn' alt='Madalyn Headshot' fluid={aboutImage.fluid} />
    </div>
    <Container sx={{ ml: ['auto', 4], fontSize: [1, 2, 3] }}>
      <MDXRenderer>{aboutText.body}</MDXRenderer>
    </Container>
  </section>
)
