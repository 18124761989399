/** @jsx jsx */
import { jsx, Card } from 'theme-ui'
import { Link } from 'gatsby'

const Post = ({ post, prefix }) => {
  return (
    <Card
      sx={{
        display: 'grid',
        gridTemplateRows: '2fr 5fr',
        alignContent: 'center',
        height: '250px',
      }}
    >
      <small sx={{ fontWeight: 'bold', color: 'contrastPurple' }}>
        {post.date}
      </small>
      <h2
        sx={{
          m: 0,
          mb: 4,
          mt: -3,
          fontSize: [3, 4, 3],
          alignSelf: 'center',
        }}
      >
        <Link
          to={`${prefix === true ? 'blog/' : ''}${post.slug}`}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            ':hover,:focus': {
              color: 'primary',
              textDecoration: 'underline',
            },
          }}
        >
          {post.title}
        </Link>
      </h2>
    </Card>
  )
}

export default Post
