import React from 'react'
import Layout from '../gatsby-theme-blog/components/layout'
import Madalyn from '../components/madalyn'
import About from '../components/about'
import Recents from '../components/recents'

export default ({ data }) => (
  <Layout
    title={`Madalyn Parker`}
    excerpt={`Madalyn Parker's website and blog`}
  >
    <Madalyn />
    <main>
      <About
        aboutImage={data.aboutImage.childImageSharp}
        aboutText={data.aboutText}
      />
      <Recents posts={data.recents.nodes} />
    </main>
  </Layout>
)

export const pageQuery = graphql`
  query {
    aboutImage: file(relativePath: { regex: "/about-image/" }) {
      childImageSharp {
        fluid(maxWidth: 1240) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    aboutText: mdx(frontmatter: { title: { eq: "About" } }) {
      body
    }
    recents: allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      limit: 2
      filter: { frontmatter: { date: { gt: "'2001-01-01'" } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMM DD, YYYY")
          title
          slug
        }
      }
    }
  }
`
