/** @jsx jsx */
import { jsx } from 'theme-ui'

import Card from './post-card'
const Recents = ({ posts }) => (
  <section sx={{ px: 4, textAlign: 'center' }}>
    <h2 sx={{ variant: 'text.sectionHeading' }}>Recent Posts</h2>
    <ul
      sx={{
        listStyle: 'none',
        display: 'grid',
        textAlign: 'center',
        gridGap: 3,
        gridTemplateColumns: [`1fr`, 'repeat(2, minmax(250px, 1fr))'],
        gridAutoRows: `1fr`,
        m: 0,
        px: [2, 3, 5],
        py: [2, 3, 4],
      }}
    >
      {posts.map(post => (
        <li
          key={post.frontmatter.slug}
          sx={{
            border: '1px solid',
            borderColor: 'green',
            p: [2, 3, 4],
            display: 'grid',
          }}
        >
          <Card post={post.frontmatter} prefix={true} />
        </li>
      ))}
    </ul>
  </section>
)

export default Recents
